import styled from 'styled-components';

interface ButtonProps {
  readonly secondary: boolean;
}

export const Button = styled.button<ButtonProps>`
  border: none;
  -webkit-appearance: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  cursor: pointer;
  color: white;
  border-radius: 6px;
  padding: 0.75rem 2rem 0.75rem 2rem;
  background: linear-gradient(145deg, #e2322e, #be2a27);
  box-shadow: 3px 3px 20px #b94b55;
  border: none;

  &:focus {
    outline: none;
  }

  &:disabled {
    background: gray;
  }

  ${({ secondary }) =>
    secondary &&
    `
		background: #001F3F;
    box-shadow: none;
	`}
`;
