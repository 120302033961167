import React, { useEffect, useRef } from 'react';
import { Container } from 'components/common';
import styled from 'styled-components';
import social from './social.json';
import backgroundAnimationData from '../../../assets/lottie/wavey_border.json';

export const Footer = () => {
  const ref = useRef(null);

  //import lottie as clientside module
  useEffect(() => {
    import('@lottiefiles/lottie-player').then(() => {
      if (ref.current.load) {
        ref.current.load(backgroundAnimationData);
      }
    });
  });

  return (
    <Wrapper>
      <lottie-player
        ref={ref}
        autoplay
        loop
        mode='normal'
        preserveAspectRatio='none'
        style={{
          backgroundColor: 'pink',
          left: 0,
          top: -800,
          width: '100%',
          height: '180%',
          position: 'absolute',
          zIndex: -1,
        }}
      />
      <Flex as={Container}>
        <Details>
          <h2>Sebastian White</h2>
          <span>© All rights are reserved | {new Date().getFullYear()}</span>
        </Details>
        <Links>
          {social.map(({ id, name, link, icon }) => (
            <a
              key={id}
              href={link}
              target='_blank'
              rel='noopener noreferrer'
              aria-label={`follow me on ${name}`}
            >
              <img width='32' src={icon} alt={name} />
            </a>
          ))}
        </Links>
      </Flex>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 28rem 0 4rem 0;

  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;

  @media (max-width: 1960px) {
    padding: 14rem 0 4rem;
  }
  position: relative;
  overflow: hidden;
`;

const Flex = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  @media (max-width: 680px) {
    flex-direction: column;
    text-align: center;
    align-items: center;
  }
`;

const Links = styled.div`
  display: flex;
  align-items: center;

  a {
    margin: 0 0.5rem;

    img {
      margin: 0;
    }
  }
`;

const Details = styled.div`
  h2,
  a,
  span {
    color: white;
  }

  @media (max-width: 680px) {
    margin-bottom: 2rem;
  }
`;
